exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-apollo-tsx": () => import("./../../../src/pages/apollo.tsx" /* webpackChunkName: "component---src-pages-apollo-tsx" */),
  "component---src-pages-benfica-tsx": () => import("./../../../src/pages/benfica.tsx" /* webpackChunkName: "component---src-pages-benfica-tsx" */),
  "component---src-pages-case-studies-tsx": () => import("./../../../src/pages/case-studies.tsx" /* webpackChunkName: "component---src-pages-case-studies-tsx" */),
  "component---src-pages-cineman-tsx": () => import("./../../../src/pages/cineman.tsx" /* webpackChunkName: "component---src-pages-cineman-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-download-tsx": () => import("./../../../src/pages/download.tsx" /* webpackChunkName: "component---src-pages-download-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-leia-tsx": () => import("./../../../src/pages/leia.tsx" /* webpackChunkName: "component---src-pages-leia-tsx" */),
  "component---src-pages-marketplace-tsx": () => import("./../../../src/pages/marketplace.tsx" /* webpackChunkName: "component---src-pages-marketplace-tsx" */),
  "component---src-pages-paok-tsx": () => import("./../../../src/pages/paok.tsx" /* webpackChunkName: "component---src-pages-paok-tsx" */),
  "component---src-pages-platform-compatibility-tsx": () => import("./../../../src/pages/platform-compatibility.tsx" /* webpackChunkName: "component---src-pages-platform-compatibility-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-pages-ultimate-pool-tsx": () => import("./../../../src/pages/ultimate-pool.tsx" /* webpackChunkName: "component---src-pages-ultimate-pool-tsx" */)
}

